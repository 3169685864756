import React from "react"
import BlankCard from "../../components/app/app-components/blankCard"
import AppLayout from "../../components/app/app-components/layout"

const Blog = ({ path }) => {
  return (
    <AppLayout title="Articles" path={path}>
      <div className="m-5 space-y-4">
        <div className="mb-4 font-base">Latest Articles</div>
        <BlankCard text="Coming Soon..." />
        <div className="pt-8 mb-4"></div>
      </div>
    </AppLayout>
  )
}

export default Blog
