import React from "react"
import { Link, navigate } from "gatsby"
import { UserCircleIcon, ArrowSmLeftIcon } from "@heroicons/react/solid"
import { BellIcon } from "@heroicons/react/outline"

const Headbar = ({ isBackButton, title }) => {
  const navigateUrl = () => {
    if (navigate(-1)) {
      navigate(-1)
    } else {
      navigate("/app/home")
    }
  }

  return (
    <div
      id="headbar"
      className="fixed top-0 left-0 right-0 z-50 bg-white border-b h-14 "
    >
      <div className="flex items-center justify-between w-full px-2">
        {isBackButton ? (
          <button
            onClick={() => navigate(-1)}
            className="p-2 focus:outline-none"
          >
            <ArrowSmLeftIcon className="w-8 h-8" />
          </button>
        ) : (
          <Link to={`/app/profile`} className="p-2">
            <UserCircleIcon className="w-8 h-8" />
          </Link>
        )}

        <div className="p-4">{title ? title : ""}</div>
        <Link to={`/app/notifications`} className="p-2">
          <BellIcon className="w-6 h-6" />
        </Link>
      </div>
    </div>
  )
}

export default Headbar
